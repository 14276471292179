body {
  margin: 0;
  padding: 0;
  font-family: 'Manrope', sans-serif;
  /* background-color:#F9FAFB; */
}

:root {
  --primary-color: #B53673;
  --accent-color: #F9EFF4;
  --black-color: #000000;
  --white-color: #ffffff;
  --grey-label: #49546B;
  --light-black: #1E1E1E;
  --background-color: #F6F8F9;
  --verified-color: #187BEF;
}

/* Custom scrollbar styling */
::-webkit-scrollbar {
  width: 7px;
  /* Scrollbar width */
  background-color: var(--white-color);
  /* Scrollbar background color */
}

::-webkit-scrollbar-thumb {
  border-radius: 10px;
  /* Rounded corners for the scrollbar thumb */
  background-image: linear-gradient(to top,
      #D9D9D9 0%,
      #BFBFBF 50%,
      #A6A6A6 100%);
  /* Gradient for the scrollbar thumb */
}

/* Optional: Styling for scrollbar track (background area of scrollbar) */
::-webkit-scrollbar-track {
  background-color: var(--white-color);
  /* Same as the scrollbar background color */
}

/* .Approved {
  background-color: #EEF8EE;
  color: #00914B;
}

.Pending {
  background-color: #FFF3D3;
  color: #B45309;
}

.Rejected {
  background-color: #FFF3D3;
  color: #B45309;
} */
.consultation-card {
  border: 1px solid #F0D7E3;
  background-color: #FFF4F7;
}

.prescription-card {
  border: 1px solid #DED5FC;
  background-color: #F1EDFF;
}

.lab-card {
  border: 1px solid #FDD5D0;
  background-color: #FFF0EE;
}

.day-picker {
  margin: 0 !important;
  --rdp-cell-size: 48px !important;
  --rdp-caption-font-size: 18px !important;
  --rdp-accent-color: var(--primary-color) !important;
  /* --rdp-accent-color-dark:	red!important; */
  --rdp-background-color: var(--accent-color) !important;
  /* --rdp-background-color-dark:	#180270; */
  /* --rdp-outline:	2px solid #0000ff; */
  /* --rdp-outline-selected:	3px solid #0000ff!important; */
  --rdp-selected-color: var(--white-color) !important;
}

.day-picker .rdp-caption_label {
  color: var(--grey-label);
  font-weight: 600;
}

.day-picker .rdp-nav_button_next:hover {
  background-color: inherit !important;
}

.day-picker .rdp-nav_button_previous:hover {
  background-color: inherit !important;
}

.day-picker .rdp-day {
  font-weight: 500;
  color: var(--primary-color);
  background-color: #FDEDF5;
}

/* .day-picker .rdp-day:hover {
  background-color: #f5b5d5;
} */


/* #f5b5d5 */

.day-picker .rdp-day_selected {
  font-weight: 600;
  color: var(--white-color);
  background-color: var(--primary-color);
}

.day-picker .rdp-head_cell {
  font-weight: 500;
}

.day-picker .rdp-cell {
  padding: 4px 12px;
}

.day-picker .rdp-cell .rdp-day_disabled {
  background-color: inherit;
  color: #666F82;
}

/*  healthie form style */

.healthie-form {
  font-family: 'Manrope';
  /* display: flex;
  flex-direction: column;
  gap: 16px; */
}

/* form text field */

.healthie-form .form-field-container .input-wrapper .label {
  font-size: 16px;
  color: var(--light-black);
  font-weight: 600;
}

.healthie-form .form-field-container .input-wrapper .text-field input {
  /* min-height:  2rem; */
  border-radius: 8px;
  padding: 12px 14px;
  font-size: 1rem;
  border: 1px solid #c4c4c4;
}

.healthie-form .form-field-container .input-wrapper .input-inner .text-field input {
  /* min-height:  2rem; */
  border-radius: 8px;
  padding: 12px 14px;
  font-size: 1rem;
  border: 1px solid #c4c4c4;
}

.healthie-form .form-field-container .input-wrapper .input-inner-row .text-field .text-field input {
  min-width: 100px;
}

.healthie-form .form-field-container .input-wrapper .input-inner .text-field input::placeholder {
  color: #949494;
}

.healthie-form .form-field-container .input-wrapper .text-field input:hover {
  border: 1px solid var(--black-color);
}

.healthie-form .form-field-container .input-wrapper .text-field input:focus-visible {
  outline: none;
  border: 2px solid var(--primary-color);
}

/* textarea field */

.healthie-form .form-field-container .input-wrapper .textarea-field .editor-wrapper .editor-container {
  border: 1px solid #c4c4c4;
  border-radius: 8px;

}

.healthie-form .form-field-container .input-wrapper .textarea-field .editor-wrapper .editor-container:hover {
  border: 1px solid var(--black-color);
}

/* form number field  */
.healthie-form .form-field-container .input-wrapper .number-field input {
  /* min-height:  2rem; */
  border-radius: 8px;
  padding: 12px 14px;
  font-size: 1rem;
  border: 1px solid #c4c4c4;
}

.healthie-form .form-field-container .input-wrapper .input-inner .number-field input::placeholder {
  color: #949494;
}

.healthie-form .form-field-container .input-wrapper .number-field input:hover {
  border: 1px solid var(--black-color);
}

.healthie-form .form-field-container .input-wrapper .number-field input:focus-visible {
  outline: none;
  border: 2px solid var(--primary-color);
}

/* form radio button */
/* .healthie-form .form-field-container .input-wrapper .radio-group {
  display: flex;
  flex-direction: row;
  gap: 8px;
  align-items: center;
  font-size: 14px;
} */

/* table field input */

.healthie-form .form-field-container .matrix-field-wrapper .label {
  font-size: 16px;
  color: var(--light-black);
  font-weight: 600;
}

.healthie-form .form-field-container .matrix-field-wrapper .matrix-table-wrapper .matrix-field {
  background-color: #F0D7E3;
  border-radius: 8px;
  padding: 12px;
}

.healthie-form .form-field-container .matrix-field-wrapper .matrix-table-wrapper .matrix-field .matrix-table-tbody .matrix-table-row .matrix-table-cell input:focus-visible {
  outline: none;
  border: 1px solid var(--primary-color);
}

.healthie-form .form-field-container .input-wrapper .radio-group {
  gap: 12px !important;
}

.healthie-form .form-field-container .input-wrapper .radio-group .radio-wrapper {
  display: flex;
  align-items: center;
}

/* Checkbox input */

.healthie-form .form-field-container .input-wrapper .checkbox-group .checkbox-wrapper {
  display: flex;
  gap: 4px;
  align-items: end;
}

/* datepicker input */

.healthie-form .form-field-container .input-wrapper .text-field .input-date-picker {
  width: 100%;
}

.healthie-form .form-field-container .input-wrapper .date-field .input-wrapper .text-field .input-text {
  min-width: 80px;
}

.healthie-form .submit-button-container .submit-button {
  background-color: var(--primary-color);
  color: var(--white-color);
  padding: 0px;
  border-radius: 8px;
  width: 100%;
}

.healthie-form .submit-button-container .submit-button:hover {
  background-color: rgb(126, 37, 80);
  border-color: rgb(126, 37, 80);
}

/* .about-me .healthie-container .healthie-form .form-field-container .input-wrapper .read-only-field div p {
  background-color: #FFEDEC;
  padding: 8px 16px;
  border-radius: 4px;
} */


/* care plan container */

.care-plan-container {
  display: flex;
  flex-direction: column;
  gap: 14px;
  background-color: #F8F8F8;
  border-radius: 8px;
  padding: 18px;
}

/* Healthie conversation container */


.conversation-container {
  border-right: 1px solid #DEDFE4;
}

.conversation-container .conversation-list {
  padding: 0px 0px 20px 0px !important;
  /* border-right: 1px solid #DEDFE4; */
  /* border-radius: 8px 0px 0px 8px; */
  height: fit-content !important;
  overflow-y: auto !important;
}

.conversation-container .conversation-list .conversation-list-item {
  padding: 16px 24px;
  align-items: center;
}

.conversation-container .conversation-list .conversation-list-item .conversation-list-item-inner .conversation-title {
  font-size: 14px;
  font-weight: 600;
  color: var(--black-color);
}

.conversation-container .conversation-list .conversation-list-item .conversation-list-item-inner .conversation-description {
  font-size: 12px;
  color: var(--grey-label);
}

.conversation-container .conversation-list .active {
  background-color: #DEDFE4 !important;
  /* border-radius:4px; */
}

/* Healthie Chat container */


.chat-container div>div>div>div>.chat-message-divider {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px !important;
  color: #8990A0;
  font-weight: 400;
  gap: 16px;
  /* Adjust gap between text and divider */
}

.chat-container div>div>div>div>.chat-message-divider::before,
.chat-container div>div>div>div>.chat-message-divider::after {
  content: "";
  flex: 1;
  height: 1px;
  background-color: #F1F2F4;
  /* Divider color */
}

.chat-container .chat-message-list div>div>div>div>.chat-message .chat-message-container .chat-message-footer {
  font-weight: 500 !important;
  font-size: 12px;
}

.chat-container .chat-message-list div>div>div>div>.chat-message .chat-message-container .chat-message-wrapper .chat-message-footer {
  color: #8990A0 !important;
  font-size: 12px !important;
}

.chat-container .chat-message-list {
  background-color: #ffffff !important;
}

.chat-container .chat-message-list .chat-message .incoming .chat-message-container .chat-message-wrapper {
  background-color: #000000 !important;
}

.chat-container .chat-input-wrapper {
  background-color: #F0D7E3 !important;
  /* border-radius: 0px 0px 8px 0px; */
}

.chat-container .primary-button {
  background-color: var(--primary-color) !important;
}


/* .chat-container .editor-wrapper .editor-toolbar-container {
    border: 1px solid black;
    border-top: 0px;
border-bottom: 0px;
  } */

.chat-container .editor-wrapper .editor-container {
  padding: 20px 10px 10px 10px !important;
  /* border: 1px solid black;
border-top: 0px;
border-bottom: 0px; */
}

/* .chat-container .primary-button:disabled {
  background-color: #B53673!important;
} */

/* invoices container */
.invoices-container {
  height: calc(100vh - 230px);
  overflow: auto;
}

/* notifications container */
.notifications-container {
  height: calc(100vh - 265px);
  overflow: auto;
}

/* .invoices-container .infinite-scroll-component__outerdiv .infinite-scroll-component  {
  height: 400px!important;
} */

/* products container */

.products-container {
  height: calc(100vh - 408px);
  overflow: auto;
  padding-right: 8px;
}


/* settings tab panel */

.settings-tab-panel {
  padding: 32px !important;
}

/* Auth logo */

/* @media only screen and (max-width: 600px) {
  .auth-logo svg {
      width: 140px;
      height: 44px;
  } 
} */


/* input field */
.input-field .MuiInputBase-root .MuiInputBase-input::placeholder {
  color: rgba(100, 111, 132, 1);
  opacity: 1;
}

/* time slots */

.time-slots .time-slot {
  min-width: 155px
}

/* health articles */

.health-articles-container {
  height: calc(100vh - 237px);
  overflow: auto;
  padding: 0px 12px 0px 0px;
}

.summary-product-image {
  height: 99px;
  width: 94px;
  min-width: 0px;
  border-radius: 8px;
  /* object-fit: contain; */
  background-color: var(--background-color);
}


/* cart action buttons */

.cart-action-button {
  max-height: 36px !important;
  font-size: 12px !important;
}


/* supplement order listing  */

.image-card {
  width: 36px;
  /* Adjust size as needed */
  height: 36px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
  position: relative;
  background: #f0f0f0;
  /* Light gray background */
  transition: transform 0.3s ease;
  /* border: 1px solid #DDDFE4; */
}

.image-card img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

/* .image-card:nth-child(1) {
  z-index: 3;
  transform: translateX(0);
}

.image-card:nth-child(2) {
  z-index: 2;
  transform: translateX(-20px);
}

.image-card:nth-child(3) {
  z-index: 1;
  transform: translateX(-40px);
} */

/* .image-card:hover {
  transform: scale(1.05)!important;
  z-index: 5!important;
} */

.dashboard-page {
  padding: 24px;
}

.dashboard-container {
  display: flex;
  flex-direction: row;
  gap: 16px;
  /* padding: 24px; */
  justify-content: flex-start;
  flex-wrap: nowrap;
  /* width: 100%; */
}

.dashboard-content {
  flex-grow: 1;
  min-width: 0;
  height: calc(100vh - 200px);
  overflow: auto;
  scrollbar-width: none;
  /* Firefox */
  -ms-overflow-style: none;
  /* Internet Explorer 10+ */
  /* width: clamp(500px, calc(100vw - 624px), 100%); */
}

.dashboard-content::-webkit-scrollbar {
  display: none;
  /* Chrome, Safari, and newer Edge */
}

.dashboard-profile {
  min-width: 280px;
  flex-shrink: 0;
  width: max(280px, 20%);
  /* width: clamp(200px, 300px, 280px); */
}

.info-card {
  display: none;
  cursor: pointer;
}

.dashboard-articles {
  /* display: flex;
  flex-direction: row!important;
  justify-content: flex-start;
  align-items: center;
  gap: 8px;
  flex-wrap: wrap; */
  gap: 8px;
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(230px, 1fr));
}

/* verification chips */

.verification-chip {
  padding: 2px 8px 2px 6px !important;
  height: auto !important;
  gap: 4px;
  flex-shrink: 0;
  align-items: center;
  flex-shrink: 0;
}

.verification-chip .MuiChip-icon {
  margin: 0px !important;
  height: 18px;
}

.verification-chip .MuiChip-label {
  padding: 0px;
  line-height: 18px;
}

.verified {
  color: var(--verified-color) !important;
  background-color: #E5F6FF !important;
}

.not-verified {
  color: var(--grey-label) !important;
  background-color: var(--white-color) !important;
}

.plan-recommendation p {
  margin: 0px !important;
}

.health-plans-container {
  height: calc(100vh - 222px);
  overflow: auto;
}

@media only screen and (max-width: 1024px) {
  .dashboard-page {
    padding: 16px;
  }

  .dashboard-container {
    flex-direction: column-reverse;
    gap: 16px;
  }

  .dashboard-content {
    width: 100%;
    height: auto;
  }

  .dashboard-profile {
    width: 100%;
  }

  .profile-info-card {
    display: none !important;
  }

  .info-card {
    display: block;
    border-radius: 16px;
    border: 1px solid #E4E7EC;
    padding-top: 16px;
    background-color: var(--white-color);
  }
}

@media only screen and (max-width: 600px) {

  /* auth logo */
  .auth-logo svg {
    width: 140px;
    height: 44px;
  }

  /* input field */
  .input-field .MuiInputBase-root .MuiInputBase-input {
    font-size: 14px;
  }

  /* email svg */
  .email-svg svg {
    height: 150px;
  }

  /* charactrer validator */
  .character-validator .MuiSvgIcon-root {
    font-size: 14px;
  }

  .character-validator .MuiTypography-root {
    font-size: 14px;
  }

  /* day-picker */

  .day-picker {
    --rdp-cell-size: 36px !important;
    --rdp-caption-font-size: 14px !important;
  }

  .day-picker .rdp-head_cell {
    font-size: 14px;
  }

  .day-picker .rdp-cell {
    padding: 2px 4px;
    font-size: 14px;
  }

  .products-container {
    height: calc(100vh - 395px);
  }

  .health-articles-container {
    height: calc(100vh - 251px);
  }

  .dashboard-articles {
    justify-content: center;
  }
}


@media only screen and (min-width: 600px) and (max-width: 768px) {
  .time-slots .time-slot {
    font-size: 14px;
    padding: 4px 8px;
    min-width: 100px
  }

  .day-picker {
    --rdp-cell-size: 36px !important;
    --rdp-caption-font-size: 14px !important;
  }

  .day-picker .rdp-head_cell {
    font-size: 16px;
  }

  .day-picker .rdp-cell {
    padding: 2px 6px;
    font-size: 16px;
  }

  .health-articles-container {
    height: calc(100vh - 281px);
  }

}

@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .time-slots .time-slot {
    font-size: 14px;
    padding: 4px;
    min-width: 80px
  }

  .day-picker {
    --rdp-cell-size: 34px !important;
    --rdp-caption-font-size: 14px !important;
  }

  .day-picker .rdp-head_cell {
    font-size: 14px;
  }

  .day-picker .rdp-cell {
    padding: 2px 4px;
    font-size: 14px;
  }

}


@media only screen and (min-width: 1024px) and (max-width: 1280px) {
  .time-slots .time-slot {
    font-size: 16px;
    padding: 4px 12px;
    min-width: 100px
  }

  .day-picker {
    --rdp-cell-size: 40px !important;
    --rdp-caption-font-size: 16px !important;
  }

  .day-picker .rdp-head_cell {
    font-size: 16px;
  }

  .day-picker .rdp-cell {
    padding: 3px 6px;
    font-size: 16px;
  }

  .health-articles-container {
    height: calc(100vh - 262px);
  }

}


@media only screen and (min-width: 768px) and (max-width: 1024px) {
  .summary-product-image {
    height: 70px;
    width: 60px;
    min-width: 0px;
  }

  .health-articles-container {
    height: calc(100vh - 272px);
  }
}

.loader-svg {
  animation: rotate 4s linear infinite;
}

.loader-path {
  stroke-dasharray: 100;
  stroke-dashoffset: 0;
  /* animation: dash 1.5s ease-in-out infinite; */
}

@keyframes rotate {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

/* @keyframes dash {
  0% {
    stroke-dashoffset: 100;
  }

  50% {
    stroke-dashoffset: 50;
    transform: rotate(45deg);
  }

  100% {
    stroke-dashoffset: 0;
    transform: rotate(360deg);
  }
} */